<template>
  <b-form-group>
    <label for="professional">
      Profissional
    </label>
    <multiselect
      id="professional"
      class="with-border"
      v-model="professional"
      track-by="id"
      label="name"
      :options="professionals"
      :searchable="true"
      :showLabels="false"
      :allowEmpty="true"
      placeholder="Selecionar"
      @search-change="debounceProfessionals"
      @select="selectProfessional"
      @remove="clearProfessional"
      :disabled="disabled"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>
      <template slot="singleLabel" slot-scope="props">
        <div class="inline-flex">
          <Avatar v-if="professional.id" size="30px" :src="props.option.picture" />
          <div class="name">
            {{props.option.name ?? 'Selecionar'}}
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="inline-flex">
          <Avatar size="30px" :src="props.option.picture" />
          <div class="option">
            {{props.option.name}}
          </div>
        </div>
      </template>
      <template slot="noOptions">
        Digite um nome para buscar
      </template>
      <template slot="noResult">
        Nenhum resultado
      </template>
    </multiselect>
    <div v-if="validated && !value" class="custom-invalid-feedback">Campo obrigatório</div>
  </b-form-group>
</template>

<script>
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'ProfessionalSelect',
  components: { 
    Avatar: () => import('@/components/General/Avatar'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  props: {
    value: Object,
    roles: Array,
    validated: Boolean,
    professionalId: {
      type: Object,
      default: () => ({}),
    },
    selectProfessional: Function,
    clearProfessional: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      debounceProfessionals: debounce(this.searchProfessionals, 300),
      professional: this.professionalId && this.professionalId.role !== 'room' ? this.selectProfessional(this.professionalId) : '',
      professionals: [],
    }
  },
  methods: {
    searchProfessionals(query) {
      this.api.getProfessionals(1, this.clinic.id, query, this.roles)
        .then(({ data }) => {
          this.professionals = data.data
        })
        .catch(console.log)
    },
  },
  watch: {
    value: function(val) {
      this.professional = val
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-flex {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px;
  margin-top: 4px;
}
.name {
  width: 75%;
  color: var(--type-active);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.option {
  width: 250px;
  color: var(--type-active);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
</style>
